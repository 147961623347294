<template>
    <v-container fluid>
        <v-row no-gutters class="white rounded shadow1 py-2 mb-3">
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="getStock">
                    <v-row dense justify="center">
                        <v-col cols="2">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="3">Type</v-col>
                                <v-col cols="9">
                                    <v-combobox 
                                        v-model="searchType"
                                        dense
                                        outlined
                                        hide-details
                                        :items="['Total Stock', 'Current Stock', 'By Product', 'By Category', 'By Brand']"
                                    >
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3"  v-if="searchType == 'By Category'">
                            <v-row no-gutters style="margin-top: 3px;color: #000;">
                                <v-col cols="4">Category</v-col>
                                <v-col cols="8">
                                    <v-combobox 
                                        dense
                                        outlined
                                        hide-details
                                        v-model="category"
                                        @focus="$store.dispatch('category/getCategories')"
                                        :items="$store.getters['category/categories']"
                                        :loading="$store.getters['category/loadingCategories']"
                                        item-text="name"
                                        item-value="id"
                                    >
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3"  v-if="searchType == 'By Brand'">
                            <v-row no-gutters style="margin-top: 3px;color: #000;">
                                <v-col cols="4">Brand</v-col>
                                <v-col cols="8">
                                    <v-combobox 
                                        dense
                                        outlined
                                        hide-details
                                        v-model="brand"
                                        @focus="$store.dispatch('brand/getBrands')"
                                        :items="$store.getters['brand/brands']"
                                        :loading="$store.getters['brand/loadingBrands']"
                                        item-text="name"
                                        item-value="id"
                                    >
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3"  v-if="searchType == 'By Product'">
                            <v-row no-gutters style="margin-top: 3px;color: #000;">
                                <v-col cols="4">Product</v-col>
                                <v-col cols="8">
                                    <v-combobox 
                                        dense
                                        outlined
                                        hide-details
                                        v-model="product"
                                        @focus="$store.dispatch('product/getProducts')"
                                        :items="$store.getters['product/products']"
                                        :loading="$store.getters['product/loadingProducts']"
                                        item-text="display_text"
                                        item-value="id"
                                    >
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="1" class="d-flex align-center">
                            <v-btn type="submit" dark class="text_bg_fave" :loading="loading">Search</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="$store.getters['product/stock'].length">
            <v-col cols="12">
                <v-btn height="26px" @click.prevent="print" class="mb-2">Print</v-btn>
            </v-col>
        </v-row>
        <div id="printContent">
            <v-row no-gutters v-if="$store.getters['product/stock'].length">
                <v-col cols="12" v-if="searchType == 'Current Stock'">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Product Id</th>
                                    <th>Product Name</th>
                                    <th>Category</th>
                                    <th>Brand</th>
                                    <th>Purchase Rate</th>
                                    <th>Current Quantity</th>
                                    <th>Stock Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(stock, sl) in $store.getters['product/stock']" :key="sl">
                                    <td class="text-center">{{ sl + 1 }}</td>
                                    <td class="text-center">{{ stock.product.code }}</td>
                                    <td class="text-center">{{ stock.product.name }}</td>
                                    <td class="text-center">{{ stock.product.category.name }}</td>
                                    <td class="text-center">{{ stock.product.brand.name }}</td>
                                    <td class="text-center">{{ stock.product.purchase_rate }}</td>
                                    <td class="text-right">{{ stock.current_quantity }}</td>
                                    <td class="text-right">{{ stock.stock_value }}</td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="text-right"><strong>Total</strong></td>
                                    <td class="text-right"><strong>{{ totalQuantity }}</strong></td>
                                    <td class="text-right"><strong>{{ totalStockValue }}</strong></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>

                <v-col cols="12" v-if="searchType != 'Current Stock'">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Product Id</th>
                                    <th>Product Name</th>
                                    <th>Category</th>
                                    <th>Brand</th>
                                    <th>Purchase Rate</th>
                                    <th>Pur. Quantity</th>
                                    <th>Pur. Return</th>
                                    <th>Sale Quantity</th>
                                    <th>Sale Return</th>
                                    <th>Damage Quantity</th>
                                    <th>Current Quantity</th>
                                    <th>Stock Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, sl) in $store.getters['product/stock']" :key="sl">
                                    <td class="text-center">{{ sl + 1 }}</td>
                                    <td class="text-center">{{ product.code }}</td>
                                    <td class="text-center">{{ product.name }}</td>
                                    <td class="text-center">{{ product.category.name }}</td>
                                    <td class="text-center">{{ product.brand.name }}</td>
                                    <td class="text-center">{{ product.purchase_rate }}</td>
                                    <td class="text-center">{{ product.purchase_quantity }}</td>
                                    <td class="text-center">{{ product.purchaes_return }}</td>
                                    <td class="text-center">{{ product.sale_quantity }}</td>
                                    <td class="text-center">{{ product.sale_return }}</td>
                                    <td class="text-center">{{ product.damage_quantity }}</td>
                                    <td class="text-center">{{ product.current_quantity }}</td>
                                    <td class="text-right">{{ product.stock_value }}</td>
                                </tr>

                                <tr>
                                    <td colspan="11" class="text-right"><strong>Total</strong></td>
                                    <td class="text-center"><strong>{{totalQuantity }}</strong></td>
                                    <td class="text-right"><strong>{{ totalStockValue }}</strong></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <div class="white shadow1 rounded" v-else>
                <div class="no_result">
                    <div class="img"></div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'Stock',

    data: () => ({
        searchType: '',
        category: null,
        brand: null,
        product: null,
        loading: false
    }),

    computed: {
        totalQuantity() {
            return this.$store.getters['product/stock'].reduce((p, c) => { return +p + +c.current_quantity}, 0)
        },
        totalStockValue() {
            return this.$store.getters['product/stock'].reduce((p, c) => { return +p + +c.stock_value}, 0)
        },
    },

    async created() {
        // this.getCompanyProfile();
        this.setStyle()
    },

    methods: {
        getCompanyProfile() {
            // this.$store.dispatch('companyProfile/getCompanyProfile')
        },

        async getStock() {
            if(this.searchType != 'By Category') {
                this.category = null;
            }
            if(this.searchType != 'By Brand') {
                this.brand = null;
            }
            if(this.searchType != 'By Product') {
                this.product = null;
            }

            let filter = {
                productId: this.product == null ? null : this.product.id,
                categoryId: this.category == null ? null : this.category.id,
                brandId: this.brand == null ? null : this.brand.id
            }

            this.loading = true;

            if(this.searchType == 'Current Stock') {
                await this.$store.dispatch('product/getCurrentStock');
            } else {
                await this.$store.dispatch('product/getTotalStock', filter);
            }

            this.loading = false
        },

        async print(){
            let printContent = document.querySelector('#printContent').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            // let companyProfile = this.$store.getters['companyProfile/company']
            // let companyImage = companyProfile != null ? this.$store.getters['companyProfile/company'].image : ''
            // let ImagePath = companyImage ? this.apiBasePath + '/' + companyImage : '';
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Customer List</title>
                    <style>
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    ${this.searchType == '' ? 'Stock' : this.searchType } Report
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${printContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);
            
            let invoiceStyle = printWindow.document.createElement('style');
            invoiceStyle.innerHTML = this.style.innerHTML;
            printWindow.document.head.appendChild(invoiceStyle);
            printWindow.moveTo(0, 0);
            
            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle(){
            this.style = document.createElement('style');
            this.style.innerHTML = `
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }
                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
            document.head.appendChild(this.style);
        },
    }
}
</script>

<style lang="scss" scoped>
   .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
   }
   .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        // font-size: 11px;
        padding: 0px 1px;
        background: var(--theme_lighter);
        color: var(--theme_deep_dark);
        // border: 1px solid #ccc;
        text-align: center;
        height: 40px !important;
   }
   .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 40px !important;
        // padding: 0px 5px !important;
        // font-size: 12px !important;
        // border: 1px solid #ccc !important;
   }
</style>